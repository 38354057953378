import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Container,
} from "react-bootstrap";
import logo from "./logo192.png";
import { BrowserRouter as Router, Switch, Route,} from "react-router-dom";
import Home from '../Pages/Home';
import Contacts from '../Pages/Contacts';
import About from '../Pages/About';
import Catalogue from '../Pages/Catalogue';

export default class Header extends Component {
  render() {
    return (
      <>
        <Navbar  collapseOnSelect expand="md" bg="dark" variant="dark" fixed="top" >
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="30"
                width="30"
                className="d-inline-block align-top"
                alt="Logo"
              ></img>
              МордвесРитуал
            </Navbar.Brand>
            <Navbar.Toggle area-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="/"> Главная</Nav.Link>
                <Nav.Link href="/about"> О компании</Nav.Link>
                <Nav.Link href="/catalogue"> Наша продукция</Nav.Link>
                <Nav.Link href="/contacts"> Контакты</Nav.Link>
              </Nav>
              {/* <Form inline>
                <FormControl
                  type="text"
                  placeholder="Поиск"
                  className="mr-sm-2"
                ></FormControl>
                <Button variant="outline-info">Поиск</Button>
                
                
              </Form> */}
              <h4 style={{color: 'white'}}>+7 903 842-49-33</h4>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Router>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/about" component={About}/>
                <Route exact path="/catalogue" component={Catalogue}/>
                <Route exact path="/contacts" component={Contacts}/>
            </Switch>
        </Router>
      </>
    );
  }
}
