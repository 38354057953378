import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Ioann from '../Assets/01.jpg'
import Bogoyavl from '../Assets/02.jpg'
import Voskr from '../Assets/03.jpg'
import Monastery from '../Assets/04.jpg'

export default class CarouselBox extends Component {
    render() {
        return (
            <Carousel style={{marginBottom: '30px'}}>
                <Carousel.Item>
                    <img 
                    className="d-block w-100" 
                    src={Ioann}
                    alt="МордвесРитуал образец памятника"
                    />
                    <Carousel.Caption>
                        
                        
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img 
                    className="d-block w-100" 
                    src={Bogoyavl}
                    alt="МордвесРитуал образец памятника"
                    />
                    <Carousel.Caption>
                       
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img 
                    className="d-block w-100" 
                    src={Voskr}
                    alt="МордвесРитуал образец памятника"
                    />
                    <Carousel.Caption>
                      
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img 
                    className="d-block w-100" 
                    src={Monastery}
                    alt="МордвесРитуал образец памятника"
                    />
                    <Carousel.Caption>
                        
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        )
    }
}
