import React, { Component } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

export default class Map1 extends Component {
  render() {
    return (
      <YMaps>
        <Map
          defaultState={{
            center: [54.575639166774295,38.22060461973616],
            zoom: 17,
          }}
          style={{ height: "400px" }}
        >
          <Placemark geometry={[54.575639166774295,38.22060461973616]} />
        </Map>
      </YMaps>
    );
  }
}
