import React, { Component } from "react";
import { Container } from "react-bootstrap";
import church01 from "../Assets/main.jpg";
import biglogo from '../Assets/home/03.jpg'

export default class About extends Component {
  render() {
    return (
      <div
        className="p-1"
        style={{
          backgroundImage: `url(${church01})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container style={{ backgroundColor: "rgba(255, 255, 255, 0.85)", }} >
          <h3>О компании МордвесРитуал</h3>
          
          Наша компания работает на рынке ритуальных услуг с 2001 года. <br />
          За это время мы научились не только быстро и качественно выполнять
          весь спектр ритуальных услуг, но и глубоко понимать каждого клиента,
          ценить и относится к его горю как к своему. <br />
          <br />
          Сфера нашей деятельности включает весь спектр ритуальных услуг, таких
          как:
          <ul>
            <li>Консультационные услуги</li>
            <li>
              Изготовление, оформление и установка мемориальных памятников из
              гранита.
            </li>
            <li>Благоустройство мест захоронения по Тульской области</li>
            <li>Изготовление и установка оград, лавок и крестов</li>
            <li>Доставка ритуальных принадлежностей</li>
            <li>Организация и проведение похорон</li>
          </ul>
          <h5>Организация похорон</h5>
          <p>
            Основная специализация нашей компании – предоставление услуг по
            организации похорон и изготовление памятников, оград.
            <br />
            Квалифицированная помощь в организации похорон позволяет избежать
            лишних затрат и облегчить горе.
            <br />
            При обращении мы подскажем, какие документы, где и как оформить.
            <br />
            Поможем с выбором ритуальных принадлежностей с индивидуальным
            подходом <br />
            (подберем гроб, крест, ритуальную одежду и обувь, венки и другое){" "}
            <br />
            У нас всегда в наличии товары по доступным ценам, а также Вам не
            придется думать о доставке, мы сами доставим всё необходимое.
            <br />
            Мы оказываем ритуальные услуги в полном соответствии с вашими
            требованиями. Организуя похороны, наш персонал гарантирует высокое
            качество работы и, что не менее важно, внимательное отношение к
            родным и близким усопшего.
            <br />
            Мы понимаем всю боль утраты и поэтому соблюдаем все тонкости и
            традиции организации похорон.
            <br />
            Наши приоритеты в оказании похоронных услуг – ответственность и
            надежность.
          </p>
          <h5>
            Изготовление, оформление и установка мемориальных памятников из
            гранита
          </h5>
          <p>
            Гранит - идеальный материал для изготовления надгробий: эту породу
            камня не разрушают циклы замораживания и оттаивания, поскольку ее
            плотность не позволят воде проникать внутрь.  <br />
            Гранит не повреждается кислотными дождями, солнцем или физическими воздействиями.<br />
            Гранит устойчив к загрязнениям.<br />
            Памятники из гранита - это красиво и долговечно. 
            Для изготовления гранитных надгробий МордвесРитуал закупает российские и
            украинские граниты . Цветовая палитра гранитов и рисунок камня
            позволяет творчески подойти к дизайну и изготовлению ритуальных
            надгробных композиций. Особенно красиво классическое сочетание
            черного и белого цветов в мемориальный комплексах , памятники из
            красного или цветного гранита. <br /><br />
            <a href="/catalogue" class="btn btn-secondary" role='button'>Смотреть образцы памятников</a>

          </p>
         
          Не огорчайтесь если не нашли нужную вам услугу в списке. <br />К вашим услугам офисы компании в Мордвесе и Венёве.<br />
          Позвоните и специалисты МордвесРитуал попробуют решить любую вашу проблему.<br /><br />
          <img alt="МордвесРитуал логотип Мордвес Ясногорск Серебряные Пруды" src={biglogo} style={{width: '20%', }}></img><br /><br />
                    
          
          

        </Container>
      </div>
    );
  }
}
