import React, { Component } from "react";
import CarouselBox from "../Components/CarouselBox";
import { Container, CardDeck, Card, } from "react-bootstrap";
import home01 from '../Assets/home/01.jpg'
import home02 from '../Assets/home/02.jpg'
import home04 from '../Assets/home/04.jpg'
import home05 from '../Assets/home/05.jpg'

export default class Home extends Component {
  render() {
    return (
      <>
      
        <Container>
          {/* <h2 className="text-center m-4">Наши услуги</h2> */}
          <CardDeck className="text-center m-4">
            <Card text="secondary" >
           
              <Card.Body>
                <Card.Title></Card.Title>               
                <Card.Text >
                
                <CarouselBox />
                </Card.Text>
               
               
              </Card.Body>
            </Card>
     
          </CardDeck>
          <CardDeck className="m-4">
          <Card text="secondary" >
          <Card.Img alt="МордвесРитуал логотип Мордвес Венев Серебряные Пруды" variant="top" src={home04}></Card.Img>
           <Card.Body>
             <Card.Title> Контакты</Card.Title>
             <Card.Text >
               
             
               Офисы МордвесРитуал расположены в Мордвесе и Венёве. <br />Но наша деятельность не ограничена этими населенными пунктами. Мы принимаем заказы на ритуальные услуги от жителей Венёва, Серебряных Прудов, а также других населенных пунктов Тульской и Московской областей.  
               <br /><br />
               Вы можете как обратиться в офис, так и получить консультацию по телефону.<br /><br />
               
               <h5>Офис в Мордвесе:</h5>
               Адрес: Тульская область, п. Мордвес Улица Советская, д.3<br />
               <h6>Телефон: +7 905 117-98-88</h6>
               <br />
            
               <h5>Офис в Венёве</h5>
               Адрес: Тульская область, г. Венёв, Улица Володарского, д.8
               <h6>Телефоны: <br />
               +7 920 740-19-47 <br />
               +7 903 842-28-99</h6>

             </Card.Text>
            
             <a href="/contacts" class="btn btn-secondary" role='button'>Посмотреть на карте</a>
             
            
           </Card.Body>
         </Card>
            <Card text="secondary" >
              <Card.Img alt="МордвесРитуал логотип Мордвес Венев Серебряные Пруды" variant="top" src={home01}></Card.Img>
              <Card.Body>
                <Card.Title> О компании МордвесРитуал</Card.Title>
                <Card.Text >
                  Наша компания работает на рынке ритуальных услуг с 2001 года.  <br />
                 
                  За это время мы научились не только быстро и качественно выполнять весь спектр ритуальных услуг, но и глубоко понимать каждого клиента, ценить и относится к его горю как к своему. <br /><br />
                  Мы предлагаем полный спектр ритуальных услуг:
                  <ul>
                    <li>Организация похорон. От оформления документов и покупки ритуальных принадлежностей до проведения похорон</li>
                    <li>Изготовление, оформление и установка мемориальных памятников из гранита</li>
                    <li>Благоустройство мест захоронения по Тульской области</li>
                    <li>Изготовление и установка оград, лавок и крестов</li>
                    <li>Уборка могил и реставрация памятников</li>
                  </ul>
            
                  Звоните мы попробуем решить любую Вашу проблему.
                  <h3>+7 903 842-49-33</h3>

                
                </Card.Text>
                <a href="/about" class="btn btn-secondary" role='button' >Узнать больше о компании</a>
              </Card.Body>
            </Card>

            

          </CardDeck>
          
          <CardDeck className=" m-4">
          <Card text="secondary">
              <Card.Img alt="Памятники в Мордвесе Веневе Серебряных Прудах" variant="top" src={home02}></Card.Img>
              <Card.Body>
                <Card.Title> Памятники из гранита</Card.Title>
                <Card.Text>
                С 2001 года Мордвес Ритуал выполняет комплексные работы по изготовлению, оформлению и установке мемориальных памятников из гранита.<br /> <br />
                Качественный материал, выверенный дизайн и скорость изготовления - всё это мы можем предложить нашим клиентам по выгодной цене. <br /> <br />
                Наши памятники отличаются надежностью и долговечностью, прослужат Вам долго и всегда будут радовать глаз своей формой и красотой<br /> <br />
                Офисы нашей компании расположены в Мордвесе и Венёве, но мы готовы изготавливать и доставлять наши памятники и в другие районы. <br /> Мы работаем в Венёве и Серебряных Прудах, в Тульской и Московской области. <br /><br />
                Позвоните и мы изготовим и привезем нужный вам памятник в нужное Вам место!
              
                </Card.Text>              
                <a href="/catalogue" class="btn btn-secondary" role='button'>Посмотреть памятники</a>
              </Card.Body>
            </Card>
            <Card text="secondary">
              <Card.Img alt="Памятники в Мордвесе Веневе Серебряных Прудах" variant="top" src={home05}></Card.Img>
              <Card.Body>
                <Card.Title> Скидка 5%</Card.Title>
                <Card.Text>
                МордвесРитуал преоставляет скидку 5% на изготовление памятников если Вы воспользуетесь услугой по организации похорон у нас. 
             
              
                </Card.Text>              

              </Card.Body>
            </Card>
     
          </CardDeck>
         
          
          
        </Container>
        
        
      </>
    );
  }
}
