import React, { Component } from 'react'
import { Container, Nav, Tab, Row, Col } from 'react-bootstrap'

import pamyatnik01 from '../Assets/pamyatniki/01.jpg'
import pamyatnik02 from '../Assets/pamyatniki/02.jpg'
import pamyatnik03 from '../Assets/pamyatniki/03.jpg'
import pamyatnik04 from '../Assets/pamyatniki/04.jpg'
import pamyatnik05 from '../Assets/pamyatniki/05.jpg'
import pamyatnik06 from '../Assets/pamyatniki/06.jpg'
import pamyatnik07 from '../Assets/pamyatniki/07.jpg'
import pamyatnik08 from '../Assets/pamyatniki/08.jpg'
import pamyatnik09 from '../Assets/pamyatniki/09.jpg'
import pamyatnik10 from '../Assets/pamyatniki/10.jpg'
import pamyatnik11 from '../Assets/pamyatniki/11.jpg'
import pamyatnik12 from '../Assets/pamyatniki/12.jpg'
import pamyatnik13 from '../Assets/pamyatniki/13.jpg'
import pamyatnik14 from '../Assets/pamyatniki/14.jpg'
import pamyatnik15 from '../Assets/pamyatniki/15.jpg'
import pamyatnik16 from '../Assets/pamyatniki/16.jpg'
import pamyatnik17 from '../Assets/pamyatniki/17.jpg'
import pamyatnik18 from '../Assets/pamyatniki/18.jpg'
import pamyatnik19 from '../Assets/pamyatniki/19.jpg'
import pamyatnik20 from '../Assets/pamyatniki/20.jpg'
import pamyatnik21 from '../Assets/pamyatniki/21.jpg'

import ograda01 from '../Assets/ogrady/01.jpg'
import ograda02 from '../Assets/ogrady/02.jpg'
import ograda03 from '../Assets/ogrady/03.jpg'
import ograda04 from '../Assets/ogrady/04.jpg'
import ograda05 from '../Assets/ogrady/05.jpg'

import lavka01 from '../Assets/lavki/01.jpg'
import lavka02 from '../Assets/lavki/02.jpg'
import lavka03 from '../Assets/lavki/03.jpg'

import crest01 from '../Assets/crests/01.jpg'
import crest02 from '../Assets/crests/02.jpg'
import crest03 from '../Assets/crests/03.jpg'
import crest04 from '../Assets/crests/04.jpg'
import crest05 from '../Assets/crests/05.jpg'

import granit01 from '../Assets/granit/01.jpg'

export default class Catalogue extends Component {
    render() {
        return (
            <Container >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column mt-2" >
                            <Nav.Item >
                                <Nav.Link eventKey="first"  className="catlink">
                                    Памятники
                                </Nav.Link>
                                <Nav.Link eventKey="second" className="catlink">
                                    Ограды
                                </Nav.Link>
                                <Nav.Link eventKey="third"className="catlink">
                                    Лавки
                                </Nav.Link>
                                <Nav.Link eventKey="fourth"className="catlink">
                                    Кресты
                                </Nav.Link>
                                <Nav.Link eventKey="fifth"className="catlink">
                                Гранитный камень
                                </Nav.Link>

                            </Nav.Item>
                            </Nav>

                    </Col>
                    <Col sm={9}>
                        <Tab.Content className='mt-3'>
                            <Tab.Pane eventKey="first">
                            Сфера деятельности изготовления и заказа памятника является довольно узкой и незнакомой большинству людей, поэтому мы постараемся внести некоторые пояснения и дать некоторые советы при выборе камня по изготовлению памятников:
                            <ul>
                                <li> На изготовление памятников для лиц, которые пользовались услугами по захоронению нашей организации, предоставляется скидка в размере 5 % от всей стоимости заказа.</li>
                                <li>Вы можете заказать памятник который понравится лично Вам.  <br /> Несмотря на большой выбор стандартных образцов, мы подходим к каждому клиенту индивидуально и создадим памятник, таким как Вы его видите.</li>
                            </ul>

                            МордвесРитуал работает со следующими размерами памятников:
                            <table>
	<tbody>
		<tr>
			<td>Размер стелы</td>
			<td>Размер подставки</td>
		</tr>
		
		<tr>
			<td colspan="2">Круговая полировка</td>
		</tr>
		
		<tr>
			<td>80х40х5</td>
			<td>50х15х15</td>
		</tr>
		<tr>
			<td>80х40х8</td>
			<td>50х20х15</td>
		</tr>
		<tr>
			<td>100х50х5</td>
			<td>55х15х15</td>
		</tr>
		<tr>
			<td>100х50х6</td>
			<td>55х15х15</td>
		</tr>
		<tr>
			<td>100х50х8</td>
			<td>60х20х20</td>
		</tr>
		<tr>
			<td>100х50х10</td>
			<td>60х20х20</td>
		</tr>
		<tr>
			<td>100х60х5</td>
			<td>65х20х15</td>
		</tr>
		<tr>
			<td>100х60х6</td>
			<td>65х20х15</td>
		</tr>
		<tr>
			<td>110х60х6</td>
			<td>65х20х15</td>
		</tr>
		<tr>
			<td>120х60х5</td>
			<td>65х20х15</td>
		</tr>
		<tr>
			<td>120х60х6</td>
			<td>65х20х15</td>
		</tr>
		<tr>
			<td>120х60х8</td>
			<td>65х20х20</td>
		</tr>
		<tr>
			<td>120х60х10</td>
			<td>65х20х20</td>
		</tr>
		<tr>
			<td>130х70х8</td>
			<td>80х20х20</td>
		</tr>
		<tr>
			<td>140х70х8</td>
			<td>80х20х20</td>
		</tr>
		<tr>
			<td>140х70х10</td>
			<td>80х20х20</td>
		</tr>
		<tr>
			<td>150х70х10</td>
			<td>80х20х20</td>
		</tr>
		<tr>
			<td>160х70х10</td>
			<td>80х20х20</td>
		</tr>
		<tr>
			<td>170х80х10</td>
			<td>90х20х20</td>
		</tr>
		<tr>
			<td>180х90х12</td>
			<td>100х20х20</td>
		</tr>
		<tr>
			<td>200х100х12</td>
			<td>110х20х20</td>
		</tr>
	</tbody>
</table>
<br />


                                <h4> Образцы памятников </h4>
                                <img alt="Образец ритуального памятника" src={pamyatnik01} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik02} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik03} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik04} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik05} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik06} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik07} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik08} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik09} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik10} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik11} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik12} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik13} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik14} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik15} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik16} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik17} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik18} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik19} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik20} style={{marginTop: '15px'}}/><br />
                                <img alt="Образец ритуального памятника" src={pamyatnik21} style={{marginTop: '15px'}}/><br />
                                <br />
                                <br />  
                                                               
                                
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                            
                                <h4> Образцы оград </h4>
                                <img alt="Образец ритуальной ограды" src={ograda01} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной ограды" src={ograda02} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной ограды" src={ograda03} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной ограды" src={ograda04} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной ограды" src={ograda05} style={{marginTop: '15px'}}/>
                                <br />
                                <br />

                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                            Установленная на кладбище скамейка имеет понятную практическую ценность.<br /> Особенно ценен такой элемент для пожилых людей: кладбища всегда имеют довольно большую территорию, да и добираться бывает достаточно далеко. Поэтому чтобы навестить могилку близкого человека, может понадобиться преодолеть достаточно большое расстояние. А если на кладбище установлена скамейка, можно и передохнуть, и побыть подольше.<br /><br />

Часто, но далеко не всегда, скамейка устанавливается вместе со столиком. Обычно такой стол предназначается для поминания.<br /> Однако верующим людям следует помнить, что распитие спиртных напитков на кладбище вовсе не одобряется церковью. Куда более благоприятным для души покойного окажется поставленная за упокой свечка или прочтенная молитва, чем опрокинутый стакан хмельного питья.<br /><br />

Так или иначе, скамейки и столики для могилок на кладбище — вещь достаточно нужная и удобная. <br /><br />
              
                                <h4> Образцы лавок </h4>
                                <img alt="Образец ритуальной лавки" src={lavka01} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной лавки"src={lavka02} style={{marginTop: '15px'}}/>
                                <h4> Образец стола </h4>
                                <img alt="Образец ритуальной лавки"src={lavka03} style={{marginTop: '15px'}}/>
                                {/* <img alt="Образец ритуальной лавки"src={lavka04} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной лавки"src={lavka05} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной лавки"src={lavka06} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной лавки"src={lavka07} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной лавки"src={lavka08} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной лавки"src={lavka09} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуальной лавки"src={lavka10} style={{marginTop: '15px'}}/> */}
                                <br /> <br />
                                
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                            Надгробные кресты издавна традиционно украшают могилы верующих христиан. Однако в настоящее время над их значением практически никто не задумывается. Между тем, крест – один из самых древних, неоднозначных и глубоких символов.<br /><br />
                            Надгробные кресты, устанавливаемые на могилах, по мнению специалистов, могут носить разные значения. Наиболее часто встречающийся аргумент: крест как символ принадлежности усопшего человека к христианству. Не менее распространена и следующая идея, связанная со Страшным судом (традиционным для христиан верованием в возвращение Христа): вечная жизнь будет дарована только верующим, в том числе, и уже ушедшим в иной мир, а крест станет опорой, которая поможет человеку подняться навстречу спасению.<br /><br />

                            <h4> Образцы крестов </h4>
                                <img alt="Образец ритуального креста" src={crest01} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуального креста" src={crest02} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуального креста" src={crest03} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуального креста" src={crest04} style={{marginTop: '15px'}}/>
                                <img alt="Образец ритуального креста" src={crest05} style={{marginTop: '15px'}}/>
                             
                               
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                            На протяжении многих лет технологии изготовления памятников менялись. Однако сейчас, как и раньше для изготовления памятников используют в основном гранит и его разновидности.<br /> <br /> 
                            <h6>Преимущества памятников из гранита:</h6>
                            <ul>
                                <li>долговечны благодаря морозоустойчивости и коррозийной устойчивости;</li>
                                <li>не боятся солнца и практически не тускнеют;</li>
                                <li>не покрываются трещинами со временем;</li>
                                <li>не боятся никаких чистящих средств как мрамор;</li>
                                <li>не остаются разводы от осадков (дождь, снег).</li>
                            </ul>
                            <h6>О граните</h6>

Гранит – это уникальный природный камень, занимающий 2 место после алмаза по своей прочности, при этом существует огромный выбор цветов и фактур этого камня. Благодаря этому, практически, все древние архитектурные памятники созданы из гранита.<br />

Состав гранита - это в основном кварц и полевой шпат. <br />Шпат - определяет цвет гранита (чаще всего светло-серый, реже розовый, жёлтый, красный и иногда зеленый). <br />Кварц – виден как стекловидные, бесцветные зернышки (иногда голубоватые) и бывает разного диаметра менее 1 мм и до 10 мм. <br />Мелкозернистый гранит более прочный и стойкий к атмосферным явлениям и более дорогой.<br /><br />


                            <h6>МордвесРитуал предлагает только лучшие и проверенные временем образцы из Украинского гранита, <br />который очень хорошо подходит для размещения изображений </h6>
                                <img alt="Образец ритуального гранита" src={granit01} style={{marginTop: '15px'}}/>
                                <h6>Украинский черный гранит</h6> 
                                                           
                               
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>

            </Tab.Container>
        </Container>
        )
    }
}
