import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import Map1 from '../Components/Map'  
import Map3 from '../Components/Map3' 

export default class Contacts extends Component {
    render() {
        return (
           <Container >
               
               
               <h3>Наш офис в Мордвесе:</h3>
               <h6>Тульская область, Венёвский район, пос. Мордвес, ул. Советская, д.3</h6>
               <h6>Телефон: +7 905 117-98-88</h6>
              <Map1 />
              <br />
              
              <h3>Наш офис в Венёве:</h3>
              <h6>Адрес: Тульская область, г. Венёв, Улица Володарского, д.8</h6>
              <h6>Телефон: +7 920 740-19-47,  +7 903 842-28-99</h6>
              <Map3 />
              <br />    
                            
           </Container>
           
        )
    }
}

